/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";
import Logo from "assets/images/bitflow.png";

import Button from "../elements/Button";

export default function BrandIcon() {
  return (
    <Button className="flex flex-row items-center gap-2" type="link" href="/">
      <img className="w-12 h-12 " src={Logo} alt="bitflow" />
      <p className="text-4xl font-medium text-theme-blue ">
        BitFlow
        <span className="text-theme-purple">Agency</span>
      </p>
    </Button>
  );
}
